import { fiFmgHttp } from './fmg_json_api';
import type { JsonResponseResult } from './types';

export const get = function (
  url: string,
  method: string,
  params?: Record<string, any>
): Promise<JsonResponseResult> {
  return getObj({
    url: url,
    method: method,
    params: params,
  });
};
export const getObj = function (reqObj: {
  url: string;
  method: string;
  params?: Record<string, any>;
}): Promise<JsonResponseResult> {
  return fiFmgHttp
    .get({
      req: JSON.stringify(reqObj),
    })
    .then(function (resp) {
      return resp[0];
    });
};
export const post = function (
  url: string,
  method: string,
  params?: Record<string, any>
): Promise<JsonResponseResult> {
  return postObj({
    url: url,
    method: method,
    params: params,
  });
};
export const postObj = function (reqObj: {
  url: string;
  method: string;
  params?: Record<string, any>;
}): Promise<JsonResponseResult> {
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0];
  });
};
