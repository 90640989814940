import sys from './sys'; // eslint-disable-line
import fazSyntax from './fazsyntax'; // eslint-disable-line
import user from './macros_user';
import country_codes from './country_codes';
import chartThemes from './chart_themes'; // eslint-disable-line
import themeDef from './theme-def.json'; // eslint-disable-line
import defaultPalette, {
  dark as darkPalette,
} from '@fafm/neowise-themes/dist/macros_palette';
import themeVars from '@fafm/neowise-themes/dist/macros_theme';
import interop from 'PythonDir/util/proxy/interop.json'; // eslint-disable-line

const macros = {
  ...sys,
  ...fazSyntax,
  USER: user,
  COUNTRY_CODES: country_codes,
  CHART_THEMES: chartThemes,
  THEME_LIST: themeDef.themeList,
  // namespace for THEMES.GUI_THEME_...
  THEMES: themeDef.themes,
  THEME_PALETTE: { default: defaultPalette, dark: darkPalette },
  THEME_VARS: themeVars,
  INTEROP: interop,
};

export default macros;
