// Define macros only for js-client...

import { identity } from 'lodash';

import sys from './sys'; // eslint-disable-line
import fazsyntax from './fazsyntax'; // eslint-disable-line
// set a temporary gettext to prevent crash before i18n being loaded.
window.gettext = window.gettext ?? identity;
const { FAZ_SYNTAX } = fazsyntax;

const glbmc = sys.DVM;

const macros = {};

macros.PLATFORMS = [
  'FOS',
  'FOC',
  'FAZ',
  'FML',
  'FSA',
  'FWB',
  'FCH',
  'LOG',
  'FCT',
  'FMG',
  'FSW',
  'FDD',
  'FAC',
  'FPA',
  'FRA',
  'FPX',
  'FNA',
  'FFW',
  'FWC',
  'FDC',
  'FTC',
  'FTS',
  'FSR',
  'FCA',
  'FAI',
  'FAP',
];
// special platforms, their ADOM will all be Unmanaged_Devices
macros.PLATFORM_BY_ABBREV = {
  FSR: 'FortiSOAR',
  FAD: 'FortiADC',
  FTS: 'FortiTester',
  FAI: 'FortiNDR',
};
macros.PLATFORMS_DISC = [
  glbmc.DVM_RESTRICTED_PRD_FOS,
  glbmc.DVM_RESTRICTED_PRD_FOC,
  glbmc.DVM_RESTRICTED_PRD_FAZ,
  glbmc.DVM_RESTRICTED_PRD_FSW,
  glbmc.DVM_RESTRICTED_PRD_FSF,
  glbmc.DVM_RESTRICTED_PRD_FFW,
  glbmc.DVM_RESTRICTED_PRD_FWC,
  glbmc.DVM_RESTRICTED_PRD_FPX,
];

macros.DEF_ROW_HEIGHT = 28;
macros.ROW_HEIGHT_INCREMENT = 25;
macros.PNO_PLYCK_REPORT_TYPE = 3;
macros.PNO_PLYCK_REPORT_NAME = '4';
macros.PNO_POPUP_MAX_LEN = 7;
macros.SEQ_COLUMN_WIDTH_NUM = 80;
macros.SEQ_COLUMN_WIDTH = `${macros.SEQ_COLUMN_WIDTH_NUM}px`;
macros.SEQ_COLUMN_DEFAULT_WIDTH_NUM = 45;
macros.SEQ_COLUMN_DEFAULT_WIDTH = `${macros.SEQ_COLUMN_DEFAULT_WIDTH_NUM}px`;

macros.EVENT = {
  SESSION_CHANGED: 'workflow_session_changed',
  ADOM_LOCK_CHANGED: 'evtAdomLockChanged',
  WORKSPACE_DATA_UPDATED: 'evtUpdatedWorkspaceData',
};

macros.DEF = {
  //System wide
  URL_HOME: '/p/app/',
  URL_DVM_API: '/cgi-bin/module/flatui/dvm/json',
  URL_FLATUI_API: '/cgi-bin/module/flatui/json',
  URL_FLATUI_PROXY_API: '/cgi-bin/module/flatui_proxy',
};

macros.KW = {
  CAN_DELETE: 'candelete',
  SELECT_OPTION_NONE: '_none_',
  SELECT_SINGLE: 1,
  SELECT_MULTIPLE: 2,
  SELECT_NONE: 0,
};

macros.MODAL = {
  BUTTON: {
    PREVIOUS: 'previous',
    NEXT: 'next',
    FINISH: 'finish',
    CANCEL: 'cancel',
    IMPORT: 'import',
  },
};

macros.PROF = {
  NAMES: ['devprof', 'crprof'],
  TYPE_DEV: 0, //device profile (aka. provisioning template...)
  TYPE_CR: 1, //client reputation
  TYPE_CERT: 2, //client reputation
  TYPE_DEV_TEMPLATE: 3, // device templates (such as ipsec, router)
};

macros.DVM = {
  VPNMGRINTF: '/^vpnmgr_/', //reserved intf

  //DVM UI
  GROUPING_KEYWORDS: [
    'name',
    'isGroup',
    'groupName',
    'groupSpan',
    'groupSpanStatic',
    'isExpanded',
    'cd',
  ],
  LOG_DIFF: 900,

  SKIP_RELOADING: 'SKIP_RELOADING',
  SKIP_LOAD_DEFAULT: 'SKIP_LOAD_DEFAULT',
  SKIP_REMOVE_CHILDTABLE: 'SKIP_REMOVE_CHILDTABLE',
  LOADING_TIMEOUT: 'LOADING_TIMEOUT',
  DASHBOARD_ITEM_ID: 'DASHBOARD_MENU_ITEM',
  DISPLAY_OPTIONS_ITEM_ID: 'DISPLAY_OPTIONS_ITEM',
  PARAM_GROUP_SYSTEM: 'system',
  PARAM_ITEM_DASHBOARD: 'dashboard',

  //DHCP server mode. For UI showing ONLY!!!
  DHCP_SVR_DISABLE: -1,
  DHCP_SVR_SERVER: 0,
  DHCP_SVR_RELAY: 1,
  //KEY-TYPE
  KTYPE_SN: '0',
  KTYPE_PK: '1',
  //ADD-TYPE
  ADD_DEV_TYPE_DISCOVER: '1',
  ADD_DEV_TYPE_MODEL: '2',
  ADD_DEV_TYPE_HA_MODEL: '3',
  ADD_DEV_TYPE_CSV: '4',
  CHOOSE_ADD_DEV_TYPE: '5',
  HA_PRIO_MIN: 0,
  HA_PRIO_MAX: 255,
  HA_ROLE_MASTER: 1,
  HA_ROLE_SLAVE: 0,
  //FIRMWARE_IMAGE_TYPE
  FIRMWARE_IMG_FGD: '0',
  FIRMWARE_IMG_LOCAL: '1',
  // PAIRING STATE
  PAIRING_STATE_IDLE: 'idle',
  PAIRING_STATE_DETECTING: 'detecting',
  PAIRING_STATE_WATCHING_WINDOW: 'watching_window',

  //DVM Framework
  INPUT_MAX_255: 255,
  FWK_SCPMEM: 'scope member',
  FWK_RADIO: 3,
  FWK_IMP: 'has_import_data',
  FWK_CATEID: 'cfgitemid',
  FWK_TMPLID: 'prvtmpl',
  FWK_ADOM: 'KW_ADOM',
  FWK_EDITING: 'KW_EDITING',
  FWK_OBJTYPE: 'object_type',
  FWK_MKEY: 'mkey',
  FWK_ACT_REPLACE: 'replace',
  FWK_GETDEFAULT: 'get_default',
  FWK_EDTMKY: 'KW_EDITING_MKEY',
  FWK_CHILD_FULL_CATE: 'child_cate',
  FWK_CHILD_PART_CATE: 'child_cate1',

  //Admin Priv Keyward
  ADMINPRIV: 'user_profile',
  ADMINPRIV_DEV_MANAGER: 'dev_manager', //group r/w
  ADMINPRIV_DEV_CFG: 'dev_cfg',
  ADMINPRIV_DEV_CONFIG_RETRIEVE: 'dev_config_retrieve',
  ADMINPRIV_DEV_TERMACCESS: 'dev_termaccess',
  ADMINPRIV_DEV_OP: 'device_op',
  ADMINPRIV_DEV_PROFILE: 'device_profile',
  ADMINPRIV_DEPLOY_MANAGEMENT: 'deploy_management',
  ADMINPRIV_WLLB: 'wllb',
  ADMINPRIV_DEV_FORTIAP: 'dev_ap',
  ADMINPRIV_DEV_FORTISWITCH: 'dev_fs',
  ADMINPRIV_DEV_FORTIEXTENDER: 'dev_fe',
  ADMINPRIV_VPN_MANAGER: 'vpn_manager',
  ADMINPRIV_IMPORT_POLICY: 'import_policy_pack',
  ADMINPRIV_INTF_MAPPING: 'intf_mapping',
  ADMINPRIV_PP_DEV_LOCK: 'dev_ppkg_lock',
  ADMINPRIV_PO_OBJ: 'adom_policy_object',
  ADMINPRIV_IPS_OBJ_CFG: 'ips_object_cfg',
  ADMINPRIV_DEV_CONFIG_REVERT: 'dev_config_revert',
  ADMINPRIV_DEV_REVISION_DELETE: 'dev_rev_del',
  ADMINPRIV_DEV_TERMINAL_ACCESS: 'dev_termaccess',
  ADMINPRIV_FGD_CENTER: 'fgd_center',
  ADMINPRIV_FGD_LICENSE_ACCESS: 'fgd_center_licensing',
  ADMINPRIV_FGD_FMW_MGMT: 'fgd_center_fmw_mgmt',
  ADMINPRIV_ADOM_POLICY_PKG: 'adom_policy_pack',
  ADMINPRIV_GLOBAL_POLICY_PKG: 'g_policy_pack',
  ADMINPRIV_SCRIPT_ACCESS: 'script_access',
  ADMINPRIV_GLOBAL_POLICY_ACCESS: 'global-policy-packages',
  ADMINPRIV_ADOM_ACCESS: 'adom_switch',
  ADMINPRIV_SYSTEM_SETTINGS: 'system_setting',
  ADMINPRIV_FABRIC_VIEW: 'fabric_viewer',
  ADMINPRIV_DOCKER_EXTENSION: 'ext_access',

  DVM_MAPPING_NAME: 'device_group',

  LOAD_DVM_LIST: 1,
  LOAD_DVM_TREE: 2,

  LIC_SUPPORT_CONTRACT_UNKNOWN: -1,
  LIC_SUPPORT_CONTRACT_TRIAL: 0,
  LIC_SUPPORT_CONTRACT_85: 1,
  LIC_SUPPORT_CONTRACT_247: 2,
  LIC_INVALID: 0,
  LIC_VALID: 1,
  LIC_EXPIRE_SOON: 2,
  LIC_EXPIRED: 3,

  MAX_PAC_FILE_SIZE: 262144,
  DEFAULT_ALL_FGT_SCOPE_NAME: 'All_FortiGate',
  DEFAULT_ALL_FOC_SCOPE_NAME: 'All_FortiCarrier',
  DEFAULT_ALL_FFW_SCOPE_NAME: 'All_FortiFirewall',
  DEFAULT_ALL_FWC_SCOPE_NAME: 'All_FortiFirewallCarrier',

  CDB_DEFAULT_GLOBAL_NAME: 'global',

  ACTION_SUCCESS: 1,
  ACTION_FAILED: 0,

  DEV_ADMIN_TYPE_LOCAL: 0,
  DEV_ADMIN_TYPE_REMOTE: 1,
  DEV_ADMIN_TYPE_REMOTE_WILDCARD: 2,
  DEV_ADMIN_TYPE_PKI: 3,

  SDWAN_SPILLOVER_DEFAULT_MAX: 16776000,
  SDWAN_8BIT_DEFAULT_MAX: 255,
  SDWAN_16BIT_DEFAULT_MAX: 65535,
  SDWAN_32BIT_DEFAULT_MAX: 4294967295,
  SDWAN_DEFAULT_MAX_10X7: 10000000,
  SDWAN_TCP_PROTOCOL: 6,
  SDWAN_UDP_PROTOCOL: 17,
  SDWAN_DEV_V6_BUILD: 1614,
  SDWAN_DEV_BUILD_1134: 1134,
  DEFAULT_SDWAN_ZONE: 'virtual-wan-link',

  SDWAN_HEALTH_CHECK_PASS: 0,
  SDWAN_HEALTH_CHECK_FAIL: 1,
  SDWAN_HEALTH_CHECK_NA: 2, // NA -> no connection at all

  SDWAN_MONITOR_MEMBER: 0,
  SDWAN_MONITOR_HEALTHCK: 1,
  SDWAN_MONITOR_INTERFACE: 2,

  LOGGING_MODE_NA: 0,
  LOGGING_MODE_STORE_AND_UPLOAD: 1,
  LOGGING_MODE_REALTIME: 2,

  PT_TYPE: {
    TEMPLATE_GROUP: 'tmpl_grp',
    SYSTEM_TEMPLATE: 'dev_prof',
    IPSEC_TEMPLATE: 'ipsec_prof',
    ROUTE_TEMPLATE: 'router_prof',
    BGP_TEMPLATE: 'router_bgp',
    NSX_T_TEMPLATE: 'cst_prof',
    SDWAN_TEMPLATE: 'sdwan_prof',
    SDWAN_OVERLAY: 'sdwan_overlay_prof',
    CERTIFICATE_TEMPLATE: 'cert_prof',
    THREAT_WEIGHT_TEMPLATE: 'cr_prof',
    CLI_TEMPLATE: 'cli_prof',
    PRERUN_CLI_TEMPLATE: 'prerun_cliprof',
    IPS_TEMPLATE: 'ips_prof',
    FABRIC_AUTH_TEMPLATE: 'fbauth_prof',
  },

  // value comes from src/pm2/ppkg/pkg_common_obj.c -> pm3_get_object_description()
  PT_DESC: {
    SYSTEM_TEMPLATE: 'system template',
    SDWAN_TEMPLATE: 'wan template',
    UNKNOWN_TYPE: 'unknown type',
    // might add more in the future if needed
  },

  ASSETS_TMPL_TYPE: {
    AP_PROFILE: 'ap_prof',
    FSW_TEMPLATE: 'fsw_prof',
    FEXT_TEMPLATE: 'fext_prof',
  },

  DEFAULT_PRERUN_CLI_TEMPLATES: {
    PROVISION_INTERFACES_ON_VM: 'provision_interfaces_on_vm',
    SPLIT_HARDWARE_SWITCH_PORTS_40_80_100:
      'split_hardware_switch_ports_40_80_100',
    SPLIT_HARDWARE_SWITCH_PORTS_60_90: 'split_hardware_switch_ports_60_90',
  },

  HA_MODE: 'ha_mode',
  STATIC_ROUTE_DEVICE_BLACKHOLE: '-1',
  STATIC_ROUTE_DEVICE_SDWAN: '-2',

  DEVPROF_CREATE_MODE_BLANK: 'blank', // for system template create new
  DEVPROF_CREATE_MODE_DEFAULT: 'default',
  DEVPROF_CREATE_MODE_CLONE: 'clone',

  DESTINATION_UI_SUBNET: 0, // for 'dst_type_ui' subnet
  DESTINATION_UI_NAMED_ADDRESS: 1, // for 'dst_type_ui' Named Address
  DESTINATION_UI_SVC: 2, // for 'dst_type_ui' Internet Service

  // for determining a device group is reserved group or not
  DVM_DEV_GRP_TYPE_RESERVED: 1,
  DVM_DEV_GRP_TYPE_USER_DEFINED: 0,
  // only 10 devices install preview are allowed for each querying
  MAX_NUMBER_PREVIEW_QUERYING: 10,
  CONTENT_VIEW_HEIGHT: '70vh',

  DASHBOARD_WIDGET_AUTOWRAP_WIDTH: 350,
  DASHBOARD_TAB_NAME_MAXLEN: 24,
  DASHBOARD_WIDGET_TAGS: {
    SUMMARY: 'summary_dashboard',
    SECURITY: 'security_dashboard',
    NETWORK: 'network_dashboard',
    ROUTING: 'routing_dashboard',
    FORTIAP: 'fortiap_dashboard',
    USER: 'user_dashboard',
    WANOPT: 'wanopt_dashboard',
  },

  DASHBOARD_WIDGET_GUI_TYPES: {
    TABLE: 'table',
    CHART: 'chart',
    TILE: 'tile',
    LIST: 'list',
  },

  //assets view
  ASSETS_FORTIEXTENDER: 'current_asset_fortiextender',
  ASSETS_FORTIAP: 'current_asset_fortiap',
  ASSETS_FORTISWITCH: 'current_asset_fortiswitch',
  ASSETS_FORTIEXTENDER_ABBR: 'fext',
  ASSETS_FORTIAP_ABBR: 'fap',
  ASSETS_FORTISWITCH_ABBR: 'fsw',

  MAX_NUMBER_DEFAULT_VML_ADOM: 5,

  IPSEC_TEMPLATE_TYPE_SITE_TO_SITE: 1,
  IPSEC_TEMPLATE_TYPE_HUB_AND_SPOKE: 2,

  IPSEC_TEMPLATE_INTERNET_ACCESS_NONE: 0,
  IPSEC_TEMPLATE_INTERNET_ACCESS_SHARE_LOCAL: 1,
  IPSEC_TEMPLATE_INTERNET_ACCESS_USE_REMOTE: 2,

  IMPORT_POLICY_PACKGE: 1,
  IMPORT_FAP_PROFILE: 2,
  IMPORT_FSW_TEMPLATE: 4,

  CUSTOM_TEMPLATE_STYPE: {
    IPSEC: '_ipsec',
    ROUTER: '_router_static',
    CST: '_cst',
    BGP: 'router_bgp',
    IPS: '_ips_setting',
    SDWAN_OVERLAY: '_sdwan_overlay',
    FAP_SETTING: '_fap_setting',
  },

  TYPE_NAME_MAP: {
    [glbmc.DVM_OS_TYPE_FOS]: 'FortiGate',
    [glbmc.DVM_OS_TYPE_FSW]: 'FortiSwitch',
    [glbmc.DVM_OS_TYPE_FOC]: 'FortiCarrier',
    [glbmc.DVM_OS_TYPE_FFW]: 'FortiFirewall',
    [glbmc.DVM_OS_TYPE_FWC]: 'FortiFirewallCarrier',
    [glbmc.DVM_OS_TYPE_FPX]: 'FortiProxy',
    [glbmc.DVM_OS_TYPE_FML]: 'FortiMail',
    [glbmc.DVM_OS_TYPE_FAZ]: 'FortiAnalyzer',
    [glbmc.DVM_OS_TYPE_FWB]: 'FortiWeb',
    [glbmc.DVM_OS_TYPE_FCH]: 'FortiCache',
    [glbmc.DVM_OS_TYPE_FCT]: 'FortiClient',
    [glbmc.DVM_OS_TYPE_LOG]: 'FortiSyslog',
    [glbmc.DVM_OS_TYPE_FMG]: 'FortiManager',
    [glbmc.DVM_OS_TYPE_FSA]: 'FortiSandbox',
    [glbmc.DVM_OS_TYPE_FDD]: 'FortiDDos',
    [glbmc.DVM_OS_TYPE_FAC]: 'FortiAuthenticator',
    [glbmc.DVM_OS_TYPE_FNA]: 'FortiNAC',
    [glbmc.DVM_OS_TYPE_FDC]: 'FortiDeceptor',
    [glbmc.DVM_OS_TYPE_FAD]: 'FortiADC',
    [glbmc.DVM_OS_TYPE_FSR]: 'FortiSOAR',
    [glbmc.DVM_OS_TYPE_FAI]: 'FortiNDR',
    [glbmc.DVM_OS_TYPE_FPA]: 'FortiPAM',
    [glbmc.DVM_OS_TYPE_FCA]: 'FortiCASB',
    [glbmc.DVM_OS_TYPE_FTC]: 'FortiToken',
    [glbmc.DVM_OS_TYPE_FRA]: 'FortiSRA',
    [glbmc.DVM_OS_TYPE_FAP]: 'FortiAP',
  },
  EXPORT_BLUEPRINT_FIELD: {
    SERIAL_NUMBER: 'Serial Number',
    DEVICE_BLUEPRINT: 'Device Blueprint',
    DEVICE_NAME: 'Name',
    CLUSTER_ID: 'Cluster Id',
    CLUSTER_NAME: 'Cluster Name',
    PRIORITY: 'Priority',
    HA_MODE: 'HA Mode',
  },
  IMPORT_CSV_TABLE: {
    HA_MODE: {
      ACTIVE_PASSIVE: 'AP',
      ACTIVE_ACTIVE: 'AA',
    },
  },

  DVM_EXPORT_AUTOID_BUTTON: 'dvm_export_autoid_button',

  SUBNET_BIT_COUNT_IPV4: 32,

  LOGGING_DEVICES: {
    LAST_LOG_TIME: {
      '15_MINUTES': 15 * 60,
      '30_MINUTES': 30 * 60,
      '1_HOUR': 60 * 60,
      '1_DAY': 60 * 60 * 24,
    },
  },
};

macros.PNO = {
  CATEGORY: {
    APPLICATION_CUSTOM: 'application custom predefined',
    APPLICATION_CATEGORY: 'application category predefined',
  },
  CLONE_OBJECT_KEY_PREFIX: 'Clone of ',
  GUI_WAF_PROF_ACTION_MONITOR: 10,
  GUI_NORMALIZED_INTF_TYPE: {
    DEFAULT_MAP: 'default-map',
    PLATFORM_MAP: 'platform-map',
    DYNAMIC_MAP: 'perdev-map',
  },
  GLOBAL_PPKG_ASSIGN_TYPE: {
    ASSIGN_ALL: 0,
    ASSIGN_EXCLUDE: -1,
    ASSIGN_INCLUDE: 1,
  },
};

macros.FABRIC = {
  ITSM_SERVICE_NOW: 1,
  CONNECTORS: {
    THREAT_FEEDS: {
      CATEGORY: 0,
      ADDRESS: 1,
      DOMAIN: 2,
      MALWARE: 3,
    },
    SERVICE_NOW: {
      GENERIC: 0,
      SERVICENOW: 1,
      SLACK: 4, //REMEDY: 2, WORKFLOW: 3,   May add bmc and workflow back
      MSTEAMS: 5,
    },
    STORAGE: {
      AWSS3: FAZ_SYNTAX.CLOUD_STORAGE_TYPE_S3,
      AZURE: FAZ_SYNTAX.CLOUD_STORAGE_TYPE_AZURE_BLOB,
      GOOG: FAZ_SYNTAX.CLOUD_STORAGE_TYPE_GOOGLE_CLOUD,
    },
    FAZ_FMG: 'FAZ_FMG',
  },
  DEFAULT_PORT: {
    SDN_ACI: 5671,
    SDN_KUBERNETES: 6443,
  },
};

function _makeNumberOpts(n) {
  for (var i = 0, result = []; i < n; i++) {
    result.push({
      id: i,
      text: (i > 9 ? '' : '0') + i,
    });
  }
  return result;
}
macros.SYS = {
  BIG_INTEGER: Math.pow(2, 53),
  NUM_MB: 1024 * 1024,
  GB_VS_KB_NUM: 1024 * 1024,
  GB_VS_BYTE_NUM: 1024 * 1024 * 1024,
  GB_VS_MB_NUM: 1024,
  TB_VS_MB_NUM: 1024 * 1024,
  MILI_SECONDS_IN_A_DAY: 24 * 60 * 60 * 1000,
  EXPIRING_WARNING_DAYS: 30,
  NUM_TRUST_HOSTS: 10,
  WILDCARD_TRUST_HOST4: '0.0.0.0/0.0.0.0',
  WILDCARD_TRUST_HOST6: '::/0',
  DEFAULT_TRUST_HOST4: '255.255.255.255/255.255.255.255',
  DEFAULT_TRUST_HOST6: 'ffff:ffff:ffff:ffff:ffff:ffff:ffff:ffff/128',
  MAP_SERVER_HOST: 'https://mapserver.fortinet.com',
  WEB_CONSOLE_HOST: '/ui/webconsole',
  GMAP_CHILD_IFRAME: 'map_iframe',
  CONSOLE_CHILD_IFRAME: 'console_iframe',
  SELECT2_OPTIONS_PAGESIZE: 30,
  SSELECT_ITEM_HEIGHT: 30,
  SSELECT_DETAIL_ITEM_HEIGHT: 41,
  SSELECT_DETAIL_ITEM_HEIGHT_46: 46,
  SSELECT_DETAIL_ITEM_HEIGHT_55: 55,
  SSELECT_DETAIL_ITEM_HEIGHT_60: 60,
  /*used for metafields */
  MF_T_CMDB: 0,
  MF_T_DB: 1,
  MF_T_CFG: 2,
  /*admin type*/
  ADMIN_PROFILE_TYPE_REGULAR: 0,
  ADMIN_PROFILE_TYPE_RESTRICTED: 1,
  ADMIN_PROFILE_TYPE_ADOM: 99,
  /*used for csf formation notifications*/
  UNREG_DEV: 'UNREG_DEV',
  DIFF_ADOM: 'DIFF_ADOM',
  ENABLE_AUTOMATION_ID: true,
  OBJECT_EDIT_MODE: { INVALID: -1, ADD: 0, EDIT: 1, CLONE: 2 },
  DATE_SELECTS: {
    DAY_KEYS: [
      'friday',
      'monday',
      'saturday',
      'sunday',
      'thursday',
      'tuesday',
      'wednesday',
    ],
    DAY_OPTS: [
      gettext('Sunday'),
      gettext('Monday'),
      gettext('Tuesday'),
      gettext('Wednesday'),
      gettext('Thursday'),
      gettext('Friday'),
      gettext('Saturday'),
    ].map((val, idx) => {
      return { id: idx, text: val };
    }),
    HOURS: _makeNumberOpts(24),
    MINUTES: _makeNumberOpts(60),
  },
  ONE_TO_MILLI: 1000,
  DIV_TABLE: {
    ENTRY_HEIGHT_400: 400,
    ENTRY_HEIGHT_200: 200,
    ENTRY_HEIGHT_60: 60,
    ENTRY_HEIGHT_50: 50,
    ENTRY_HEIGHT_45: 45,
    ENTRY_HEIGHT_40: 40,
    ENTRY_HEIGHT_32: 32,
    ENTRY_HEIGHT_30: 30,
    ENTRY_HEIGHT_25: 25,
    ENTRY_HEIGHT_24: 24,
    ENTRY_HEIGHT_22: 22,
    ENTRY_HEIGHT_20: 20,
    ENTRY_HEIGHT_DEFAULT: 24,
    ENTRY_HEIGHT_INCREMENT: 20,
    BASE_TABLE_HEIGHT: 120,
  },
  GUI_PERM_NONE: 0,
  GUI_PERM_READ: 1,
  GUI_PERM_RDWR: 2,
  INDENT_4SPACES: '    ',

  FORTIAI_MAX_USER: 3,
};

macros.RESPOSE_STATUS = {
  OK: 0,
  NOT_OK: -1,
};

macros.PM2CAT = {
  PM2_GLB_HDR_POLICY_ID_START: 1073741825,
  PM2_GLB_HDR_POLICY_ID_MAX: 1074741824,
  PM2_GLB_FTR_POLICY_ID_START: 1074741825,
  PM2_GLB_FTR_POLICY_ID_MAX: 1075741824,
  PM2_POLICY_ID_MAX: 1072741824,
};

macros.KEYCODE = {
  RETURN: 13,
  ESC: 27,
  UP: 38,
  DOWN: 40,
};

macros.REPORT = {
  PERIOD_ID: {
    PREVIOUS_7_DAYS: 5,
    PREVIOUS_14_DAYS: 8,
    PREVIOUS_30_DAYS: 11,
    THIS_WEEK: 3,
    PREVIOUS_WEEK: 4,
    PREVIOUS_2_WEEKS: 7,
    PREVIOUS_N_HOURS: 2,
    PREVIOUS_N_DAYS: 6,
    PREVIOUS_N_WEEKS: 12,
    THIS_MONTH: 9,
    PREVIOUS_MONTH: 10,
    THIS_QUARTER: 13,
    PREVIOUS_QUARTER: 14,
    THIS_YEAR: 15,
    TODAY: 0,
    YESTERDAY: 1,
    CUSTOM: 16,
  },
};

macros.APP = {
  DVM: 'dvm',
  PNO: 'pno',
  FORTI_AP: 'fortiap',
  FEXT: 'fext',
  VPN: 'vpnmanagement',
  NOC: 'noc',
  LOG_VIEW: 'logview',
  ALERT: 'alert',
  REPORT: 'report',
  FORTI_GUARD: 'fortiguard',
  FORTI_SWITCH: 'fortiswitch',
  SYS: 'sys',
  RESTRICTED_ADMIN: 'restrictadmin',
  FABRIC: 'fabric',
  FORTI_SOC: 'fortisoc',
  DOCKER: 'docker',
};

macros.FLEXVM = {
  FLEXVM_PRODUCT_TYPE_FGT: 1,
  FLEXVM_PRODUCT_TYPE_FMG: 2,
};

export default macros;
