/* eslint-disable */
// This file is generated from css file, do not change it directly
export default {
'dark': {
  'orange-100': '#ebdba4',
  'orange-200': '#f2d643',
  'orange-300': '#ffb248',
  'orange-400': '#eb8146',
  'orange-500': '#d95850',
  'blue-100': '#cee9ff',
  'blue-200': '#70c1ff',
  'blue-300': '#3cabff',
  'blue-400': '#0091ff',
  'blue-500': '#007aff',
  'green-100': '#d3f7f2',
  'green-200': '#92ecde',
  'green-300': '#22c3aa',
  'green-400': '#10af95',
  'green-500': '#1d907d',
  'gray-100': '#eeeeee',
  'gray-200': '#cccccc',
  'gray-300': '#bbbbbb',
  'gray-400': '#aaaaaa',
  'gray-500': '#7f7f7f',
  'purple-100': '#d2d6ef',
  'purple-200': '#aebaec',
  'purple-300': '#879bce',
  'purple-400': '#6783c2',
  'purple-500': '#5f7ec2',
  'light-blue-100': '#daf0fe',
  'light-blue-200': '#b8def5',
  'light-blue-300': '#98d2f8',
  'light-blue-400': '#70c3ed',
  'light-blue-500': '#40b8ea',
  'cyan-100': '#e4f1ea',
  'cyan-200': '#c5e5d6',
  'cyan-300': '#abdac6',
  'cyan-400': '#92d7bb',
  'cyan-500': '#71c6a5',
  'light-green-100': '#e0eed7',
  'light-green-200': '#c5e2b6',
  'light-green-300': '#abd595',
  'light-green-400': '#84c569',
  'light-green-500': '#71bd51',
  'yellow-100': '#faf6d9',
  'yellow-200': '#eff0a4',
  'yellow-300': '#faf18c',
  'yellow-400': '#f9ee60',
  'yellow-500': '#f4eb22',
  'pink-100': '#ffdae1',
  'pink-200': '#f7aec2',
  'pink-300': '#ff97b7',
  'pink-400': '#ed5d8e',
  'pink-500': '#f32083',
  'red-100': '#fed0c0',
  'red-200': '#fda68a',
  'red-300': '#fe816d',
  'red-400': '#f25b40',
  'red-500': '#ed353a',
  'background-color': '#303030',
  'on-background-color': '#ffffff',
  'on-background-color-lt': '#aaaaaa',
  'layout-background-color': '#333e48',
  'grid-background-color': '#303030',
  'on-layout-background-color': '#ffffff',
  'on-grid-background-color': '#ffffff',
  'on-table-color': '#d6d6d6',
  'surface-color': '#404040',
  'on-surface-color': '#ffffff',
  'link-color': '#0091ff',
  'axis-color': '#888888',
  'bar-blue': '#5cb8ff',
  'bar-lightblue': '#b8def5',
  'bar-yellow': '#f2d643',
  'bar-red': '#f25b40',
  'bar-orange': '#ffb248',
  'bar-green': '#abd595',
  'misc-1-50': '#5d62b6',
  'misc-1-100': '#28c3be',
  'misc-1-150': '#f2726f',
  'misc-1-200': '#ffc534',
  'misc-1-250': '#5ab1ef',
  'misc-1-300': '#eb8146',
  'misc-1-350': '#8d91cb',
  'misc-1-400': '#2ec7c9',
  'misc-1-450': '#fda68a',
  'misc-1-500': '#ed5d8e',
  'misc-1-550': '#f2d643',
  'misc-1-600': '#97b552',
  'misc-1-650': '#fda68a',
  'misc-1-700': '#6f5553',
  'misc-1-750': '#61b58f',
  'misc-1-800': '#ffb248',
  'misc-2-50': '#8d91cb',
  'misc-2-100': '#44bbee',
  'misc-2-150': '#e87268',
  'misc-2-200': '#ffc532',
  'misc-2-250': '#61b58f',
  'misc-2-300': '#f7aec2',
  'misc-2-350': '#98d9b4',
  'misc-2-400': '#f7c5a0',
  'misc-2-450': '#93beff',
  'misc-2-500': '#fc97af',
  'misc-2-550': '#2ec7c9',
  'misc-2-600': '#ffb980',
  'misc-2-650': '#fbd87f',
  'misc-2-700': '#70c1ff',
  'misc-2-750': '#97b552',
  'misc-2-800': '#efa18d',
  'misc-2-850': '#aebaec',
  'misc-2-900': '#98d9b4',
  'misc-2-950': '#e5cf0d',
  'misc-2-960': '#f7aec2',
  'misc-2-970': '#92d7bb',
  'misc-3-50': '#2ec7c9',
  'misc-3-150': '#b6a2de',
  'misc-3-200': '#dc69aa',
  'misc-3-250': '#5ab1ef',
  'misc-3-300': '#ffb980',
  'misc-3-350': '#d87a80',
  'misc-3-400': '#8d98b3',
  'misc-3-450': '#e5cf0d',
  'misc-3-500': '#97b552',
  'misc-3-550': '#95706d',
  'misc-3-600': '#07a2a4',
  'misc-3-650': '#9a7fd1',
  'misc-3-700': '#588dd5',
  'misc-3-750': '#f5994e',
  'misc-3-800': '#c05050',
  'misc-3-850': '#59678c',
  'misc-3-900': '#c9ab00',
  'misc-3-950': '#7eb00a',
  'misc-3-960': '#c14089',
  'misc-3-970': '#6f5553',
  'pie-chart-level1-green': '#6dd400',
  'pie-chart-level2-orange': '#ffb248',
  'pie-chart-level3-red': '#f25b40',
  'pie-chart-bg': '#ededed',
  'surface-color-100': '#3d3d3d',
  'surface-color-200': '#3a3a3a',
  'surface-color-300': '#363636',
  'surface-color-400': '#333333',
  'surface-color-500': '#666666',
  'surface-color-600': '#8c8c8c',
  'surface-color-700': '#b3b3b3',
  'surface-color-800': '#d9d9d9',
},
'light': {
  'orange-100': '#f2d643',
  'orange-200': '#ffb248',
  'orange-300': '#eb8146',
  'orange-400': '#d95850',
  'orange-500': '#a0342c',
  'blue-100': '#70c1ff',
  'blue-200': '#3cabff',
  'blue-300': '#0091ff',
  'blue-400': '#007aff',
  'blue-500': '#2005bb',
  'green-100': '#92ecde',
  'green-200': '#22c3aa',
  'green-300': '#10af95',
  'green-400': '#1d907d',
  'green-500': '#156a5c',
  'gray-100': '#cccccc',
  'gray-200': '#bbbbbb',
  'gray-300': '#aaaaaa',
  'gray-400': '#7f7f7f',
  'gray-500': '#5d5d5d',
  'purple-100': '#aebaec',
  'purple-200': '#879bce',
  'purple-300': '#6783c2',
  'purple-400': '#5f7ec2',
  'purple-500': '#455f96',
  'light-blue-100': '#b8def5',
  'light-blue-200': '#98d2f8',
  'light-blue-300': '#70c3ed',
  'light-blue-400': '#40b8ea',
  'light-blue-500': '#279ccf',
  'cyan-100': '#c5e5d6',
  'cyan-200': '#abdac6',
  'cyan-300': '#92d7bb',
  'cyan-400': '#71c6a5',
  'cyan-500': '#4a8c73',
  'light-green-100': '#c5e2b6',
  'light-green-200': '#abd595',
  'light-green-300': '#84c569',
  'light-green-400': '#71bd51',
  'light-green-500': '#538d3a',
  'yellow-100': '#eff0a4',
  'yellow-200': '#faf18c',
  'yellow-300': '#f9ee60',
  'yellow-400': '#f4eb22',
  'yellow-500': '#dbd104',
  'pink-100': '#f7aec2',
  'pink-200': '#ff97b7',
  'pink-300': '#ed5d8e',
  'pink-400': '#f32083',
  'pink-500': '#c8196a',
  'red-100': '#fda68a',
  'red-200': '#fe816d',
  'red-300': '#f25b40',
  'red-400': '#ed353a',
  'red-500': '#ba262b',
  'background-color': '#ffffff',
  'on-background-color': '#333333',
  'on-background-color-lt': '#444444',
  'layout-background-color': '#333e48',
  'grid-background-color': '#303030',
  'on-layout-background-color': '#ffffff',
  'on-grid-background-color': '#ffffff',
  'on-table-color': '#333333',
  'surface-color': '#ebebeb',
  'on-surface-color': '#333e48',
  'link-color': '#0091ff',
  'axis-color': '#999999',
  'bar-blue': '#5cb8ff',
  'bar-lightblue': '#b8def5',
  'bar-yellow': '#f2d643',
  'bar-red': '#f25b40',
  'bar-orange': '#ffb248',
  'bar-green': '#abd595',
  'misc-1-50': '#5d62b6',
  'misc-1-100': '#28c3be',
  'misc-1-150': '#f2726f',
  'misc-1-200': '#ffc534',
  'misc-1-250': '#5ab1ef',
  'misc-1-300': '#eb8146',
  'misc-1-350': '#8d91cb',
  'misc-1-400': '#2ec7c9',
  'misc-1-450': '#fda68a',
  'misc-1-500': '#ed5d8e',
  'misc-1-550': '#f2d643',
  'misc-1-600': '#97b552',
  'misc-1-650': '#fda68a',
  'misc-1-700': '#6f5553',
  'misc-1-750': '#61b58f',
  'misc-1-800': '#ffb248',
  'misc-2-50': '#8d91cb',
  'misc-2-100': '#44bbee',
  'misc-2-150': '#e87268',
  'misc-2-200': '#ffc532',
  'misc-2-250': '#61b58f',
  'misc-2-300': '#f7aec2',
  'misc-2-350': '#98d9b4',
  'misc-2-400': '#f7c5a0',
  'misc-2-450': '#93beff',
  'misc-2-500': '#fc97af',
  'misc-2-550': '#2ec7c9',
  'misc-2-600': '#ffb980',
  'misc-2-650': '#fbd87f',
  'misc-2-700': '#70c1ff',
  'misc-2-750': '#97b552',
  'misc-2-800': '#efa18d',
  'misc-2-850': '#aebaec',
  'misc-2-900': '#98d9b4',
  'misc-2-950': '#e5cf0d',
  'misc-2-960': '#f7aec2',
  'misc-2-970': '#92d7bb',
  'misc-3-50': '#2ec7c9',
  'misc-3-150': '#b6a2de',
  'misc-3-200': '#dc69aa',
  'misc-3-250': '#5ab1ef',
  'misc-3-300': '#ffb980',
  'misc-3-350': '#d87a80',
  'misc-3-400': '#8d98b3',
  'misc-3-450': '#e5cf0d',
  'misc-3-500': '#97b552',
  'misc-3-550': '#95706d',
  'misc-3-600': '#07a2a4',
  'misc-3-650': '#9a7fd1',
  'misc-3-700': '#588dd5',
  'misc-3-750': '#f5994e',
  'misc-3-800': '#c05050',
  'misc-3-850': '#59678c',
  'misc-3-900': '#c9ab00',
  'misc-3-950': '#7eb00a',
  'misc-3-960': '#c14089',
  'misc-3-970': '#6f5553',
  'pie-chart-level1-green': '#6dd400',
  'pie-chart-level2-orange': '#ffb248',
  'pie-chart-level3-red': '#f25b40',
  'pie-chart-bg': '#ededed',
  'surface-color-100': '#efefef',
  'surface-color-200': '#f3f3f3',
  'surface-color-300': '#f7f7f7',
  'surface-color-400': '#fbfbfb',
  'surface-color-500': '#c6c6c6',
  'surface-color-600': '#a1a1a1',
  'surface-color-700': '#7d7d7d',
  'surface-color-800': '#585858',
},
'contrast': {
  'orange-100': '#ebdba4',
  'orange-200': '#f2d643',
  'orange-300': '#ffb248',
  'orange-400': '#eb8146',
  'orange-500': '#d95850',
  'blue-100': '#cee9ff',
  'blue-200': '#70c1ff',
  'blue-300': '#3cabff',
  'blue-400': '#0091ff',
  'blue-500': '#007aff',
  'green-100': '#d3f7f2',
  'green-200': '#92ecde',
  'green-300': '#22c3aa',
  'green-400': '#10af95',
  'green-500': '#1d907d',
  'gray-100': '#eeeeee',
  'gray-200': '#cccccc',
  'gray-300': '#bbbbbb',
  'gray-400': '#aaaaaa',
  'gray-500': '#7f7f7f',
  'purple-100': '#d2d6ef',
  'purple-200': '#aebaec',
  'purple-300': '#879bce',
  'purple-400': '#6783c2',
  'purple-500': '#5f7ec2',
  'light-blue-100': '#daf0fe',
  'light-blue-200': '#b8def5',
  'light-blue-300': '#98d2f8',
  'light-blue-400': '#70c3ed',
  'light-blue-500': '#40b8ea',
  'cyan-100': '#e4f1ea',
  'cyan-200': '#c5e5d6',
  'cyan-300': '#abdac6',
  'cyan-400': '#92d7bb',
  'cyan-500': '#71c6a5',
  'light-green-100': '#e0eed7',
  'light-green-200': '#c5e2b6',
  'light-green-300': '#abd595',
  'light-green-400': '#84c569',
  'light-green-500': '#71bd51',
  'yellow-100': '#faf6d9',
  'yellow-200': '#eff0a4',
  'yellow-300': '#faf18c',
  'yellow-400': '#f9ee60',
  'yellow-500': '#f4eb22',
  'pink-100': '#ffdae1',
  'pink-200': '#f7aec2',
  'pink-300': '#ff97b7',
  'pink-400': '#ed5d8e',
  'pink-500': '#f32083',
  'red-100': '#fed0c0',
  'red-200': '#fda68a',
  'red-300': '#fe816d',
  'red-400': '#f25b40',
  'red-500': '#ed353a',
  'background-color': '#000000',
  'on-background-color': '#ffffff',
  'on-background-color-lt': '#aaaaaa',
  'layout-background-color': '#333e48',
  'grid-background-color': '#000000',
  'on-layout-background-color': '#ffffff',
  'on-grid-background-color': '#ffffff',
  'on-table-color': '#d6d6d7',
  'surface-color': '#404040',
  'on-surface-color': '#ffffff',
  'link-color': '#0091ff',
  'axis-color': '#888888',
  'bar-blue': '#5cb8ff',
  'bar-lightblue': '#b8def6',
  'bar-yellow': '#f2d643',
  'bar-red': '#f25b40',
  'bar-orange': '#ffb248',
  'bar-green': '#abd595',
  'misc-1-50': '#5d62b6',
  'misc-1-100': '#28c3be',
  'misc-1-150': '#f2726f',
  'misc-1-200': '#ffc534',
  'misc-1-250': '#5ab1ef',
  'misc-1-300': '#eb8146',
  'misc-1-350': '#8d91cb',
  'misc-1-400': '#2ec7c9',
  'misc-1-450': '#fda68a',
  'misc-1-500': '#ed5d8e',
  'misc-1-550': '#f2d643',
  'misc-1-600': '#97b552',
  'misc-1-650': '#fda68a',
  'misc-1-700': '#6f5553',
  'misc-1-750': '#61b58f',
  'misc-1-800': '#ffb248',
  'misc-2-50': '#8d91cb',
  'misc-2-100': '#44bbee',
  'misc-2-150': '#e87268',
  'misc-2-200': '#ffc532',
  'misc-2-250': '#61b58f',
  'misc-2-300': '#f7aec2',
  'misc-2-350': '#98d9b4',
  'misc-2-400': '#f7c5a0',
  'misc-2-450': '#93beff',
  'misc-2-500': '#fc97af',
  'misc-2-550': '#2ec7c9',
  'misc-2-600': '#ffb980',
  'misc-2-650': '#fbd87f',
  'misc-2-700': '#70c1ff',
  'misc-2-750': '#97b552',
  'misc-2-800': '#efa18d',
  'misc-2-850': '#aebaec',
  'misc-2-900': '#98d9b4',
  'misc-2-950': '#e5cf0d',
  'misc-2-960': '#f7aec2',
  'misc-2-970': '#92d7bb',
  'misc-3-50': '#2ec7c9',
  'misc-3-150': '#b6a2de',
  'misc-3-200': '#dc69aa',
  'misc-3-250': '#5ab1ef',
  'misc-3-300': '#ffb980',
  'misc-3-350': '#d87a80',
  'misc-3-400': '#8d98b3',
  'misc-3-450': '#e5cf0d',
  'misc-3-500': '#97b552',
  'misc-3-550': '#95706d',
  'misc-3-600': '#07a2a4',
  'misc-3-650': '#9a7fd1',
  'misc-3-700': '#588dd5',
  'misc-3-750': '#f5994e',
  'misc-3-800': '#c05050',
  'misc-3-850': '#59678c',
  'misc-3-900': '#c9ab00',
  'misc-3-950': '#7eb00a',
  'misc-3-960': '#c14089',
  'misc-3-970': '#6f5553',
  'pie-chart-level1-green': '#6dd400',
  'pie-chart-level2-orange': '#ffb248',
  'pie-chart-level3-red': '#f25b40',
  'pie-chart-bg': '#ededed',
  'surface-color-100': '#333333',
  'surface-color-200': '#262626',
  'surface-color-300': '#1a1a1a',
  'surface-color-400': '#0d0d0d',
  'surface-color-500': '#666666',
  'surface-color-600': '#8c8c8c',
  'surface-color-700': '#b3b3b3',
  'surface-color-800': '#d9d9d9',
},
};
