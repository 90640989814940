export function genv4() {
  // function adapted from https://stackoverflow.com/a/2117523/1003746
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.floor(Math.random() * 16);
    var v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function gen() {
  return (
    _s42().trim() +
    '_' +
    _s42().trim() +
    '_' +
    _s42().trim() +
    '_' +
    _s42().trim()
  );
}

function _s42() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(2);
}
