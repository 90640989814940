import axios, {
  type AxiosPromise,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type AxiosError,
  isAxiosError,
} from 'axios';
import { get, isObject, toString } from 'lodash';
import { httpManager } from './http_manager';

export const fiHttp: AxiosInstance = axios.create();

// use to control the maximum number of concurrent requests,
// and find if there is duplicated request in the queue to clone the response from it
export const fiHttpManager = httpManager(fiHttp);

// the default serialization for nested objects in params changed after upgrade axios to v1
// set it manually to make the serialization same as before
fiHttp.defaults.paramsSerializer = (params: Record<string, any>): string =>
  serialize(params);

type ResponseData = Record<string, any>;

export function fiHttpRespData<T = ResponseData>(
  prom: AxiosPromise<T>
): Promise<T> {
  return prom.then(
    (resp) => {
      return resp?.data;
    },
    (err: AxiosError<T> | { data: any }) => {
      // catch $http, err.data and axios, err.reponse.data
      return Promise.reject(
        (isAxiosError(err) ? err.response?.data : err.data) as Error
      );
    }
  );
}

export function fiHttpGet<T = ResponseData>(
  url: string,
  config: AxiosRequestConfig = {}
): Promise<T> {
  const cfg = Object.assign({}, config);
  return fiHttpRespData(fiHttp.get<T>(url, cfg));
}

export function fiHttpPost<T = ResponseData>(
  url: string,
  data?: any,
  config: AxiosRequestConfig = {}
): Promise<T> {
  const cfg = Object.assign({}, config);
  return fiHttpRespData(fiHttp.post<T>(url, data, cfg));
}

export function fiHttpForm<T = ResponseData>(
  url: string,
  data?: any,
  config: AxiosRequestConfig = {}
): Promise<T> {
  const cfg = Object.assign(
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },

      transformRequest: [
        (data: unknown /*headers*/) => {
          return serialize(data);
        },
      ],
    },
    config
  );
  return fiHttpRespData(fiHttp.post<T>(url, data, cfg));
}

/**
 * When success, pass resp.data to next handler.
 * When failure, if err.data.type is application/json,
 * then the blob err.data will be parsed to a JSON object.
 * If err.data.type is not json, err.data will be
 * given to next handler untouched.
 */
export function fiHttpBlobData<T = ResponseData>(
  prom: AxiosPromise<T> | Promise<T>
): Promise<T> {
  return prom.then(
    function (resp: AxiosResponse<T> | T) {
      return isObject(resp) && 'data' in resp ? resp.data : resp;
    },
    function (err: AxiosError<T> | { data: any }) {
      /**
       * Note:
       * if prom is $http, the err.data
       * if prom is axios instance, the err.reponse.data
       *  */

      const data = isAxiosError(err) ? err.response?.data : err.data;

      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        if ((data.type + '').indexOf('application/json') !== -1) {
          // FileReader.onloadend
          //
          // A handler for the loadend event. This event is triggered each
          // time the reading operation is completed (either in success or
          // failure).
          //
          // Thus this function will always be called and this promise will
          // always be rejected.
          reader.onloadend = function () {
            reject(JSON.parse(reader.result as string) as Error);
          };
          reader.readAsText(data as Blob);
        } else {
          reject(data as Error);
        }
      });
    }
  );
}

function serialize(data: unknown): string {
  // If this is not an object, defer to native stringification.
  if (!isObject(data)) {
    return data === null ? '' : toString(data);
  }
  const buffer = [];
  // Serialize each key in the object.
  for (const name in data) {
    if (!Object.prototype.hasOwnProperty.call(data, name)) {
      continue;
    }
    const value = (data as Record<string, any>)[name];
    buffer.push(
      encodeURIComponent(name) +
        '=' +
        encodeURIComponent(
          value === null
            ? ''
            : isObject(value)
            ? JSON.stringify(value)
            : toString(value)
        )
    );
  }
  // Serialize the buffer and clean it up for transportation.
  const source = buffer.join('&').replace(/%20/g, '+');
  return source;
}

export const getResponseData = (resp: any, defaultVal?: any): any => {
  return get(resp, '0.data', defaultVal);
};

export function genUrlWithQueryParams(
  url: string,
  params: Record<string, string | number | boolean>
): string {
  const paramsList: string[] = [];
  for (const [key, val] of Object.entries(params)) {
    paramsList.push(`${key}=${val}`);
  }
  return `${url}?${paramsList.join('&')}`;
}
