import type { AxiosError, AxiosResponse } from 'axios';
import { fiHttp } from './http';
import { genv4 } from 'fi-web/fi-uuid';
import type {
  JsonRequest,
  JsonResponse,
  JsonResponseDataV1,
  JsonResponseDataV2,
  JsonResponseError,
  JsonResponseResult,
  JsonResponseStatus,
} from './types';

type FazUrlType = 'query' | 'forward';

let _recording: boolean = false;
const _uuids: Set<string> = new Set();

const ERR_CODE_CLIENT_ABORT: number = 452;

const _base_urls: { [key in FazUrlType]: string } = {
  query: '/cgi-bin/module/fazapi',
  forward: '/cgi-bin/module/forward', // only for cancel
};

export const fiFazHttp = {
  startRecording,
  stopRecording,
  query,
};

const isObject = (val: unknown): boolean => {
  return Object.prototype.toString.call(val) === '[object Object]';
};

function startRecording(): void {
  _recording = true;
}

function stopRecording(): void {
  _recording = false;

  if (_uuids.size) {
    sendCancel(Array.from(_uuids));
  }

  // clear uuids
  _uuids.clear();
}

function sendCancel(id: string[] | string): void {
  const ids = Array.isArray(id) ? id : [id];
  const req = {
    id: '1',
    method: 'exec',
    params: [
      {
        data: {
          id: ids,
        },
        url: 'sys/request/cancel',
      },
    ],
  };

  fiHttp
    .post(_getBaseURL('forward'), req, {
      params: {
        nocache: Date.now(),
      },
    })
    .catch((err: AxiosError) => err);
}

function rejectWithCancel(reject: (reason?: JsonResponseStatus) => void): void {
  return reject({
    code: ERR_CODE_CLIENT_ABORT,
    message: gettext('Request was aborted.'),
  });
}

function _addUUID({ id, method }: JsonRequest): void {
  // Mantis 616271, to avoid cancel "update" and "set" json requests
  // only block 'get' method.
  if (method === 'get') {
    _uuids.add(id as string);
  }
}

function _rmUUID(uuid: string): void {
  _uuids.delete(uuid);
}

/**
 * send request to /cgi-bin/module/forward
 * GUI server side will NOT parse json response and directly send back to client side
 * for performance boost, however, it will be out of control/scan/checking in GUI server side.
 * @param {JsonRequest} req
 * @param {Boolean=false} rpcCache - true to enable RPC cache
 * @param {AbortController=} abortCtrl
 * @returns {Promise<JsonResponseResult[]>}
 */
function query(
  req: JsonRequest,
  rpcCache: boolean = false,
  abortCtrl?: AbortController
): Promise<JsonResponseResult[]> {
  type Data = JsonResponseDataV1 | JsonResponseDataV2;
  const url = _getBaseURL('query', rpcCache);

  return new Promise(function (resolve, reject) {
    const uuid = genv4();
    if (req && isObject(req)) {
      req.id = uuid;
      if (_recording) {
        _addUUID(req);
      }
    }

    const nReq = req;

    fiHttp
      .post<JsonResponse<Data>>(url, nReq, {
        params: {
          nocache: Date.now(),
        },
        signal: abortCtrl?.signal,
      })
      .then(
        function (resp) {
          _rmUUID(uuid);

          let idx: number,
            len: number,
            result: JsonResponseResult[],
            resultElm: JsonResponseResult,
            cnt: number = 0;
          const errs: JsonResponseResult[] = [];

          const timeStamp = getTimestampFromHttpHead(resp);
          const resp_timestamp = getTimestampFromHttpHead(
            resp,
            'x-time-response'
          );
          const respData = resp.data;

          if ('result' in respData) {
            // restrict admin profile response
            result = respData.result;
          } else if ('data' in respData) {
            // normal admin response
            result = respData.data.result || [];
          } else {
            reject(
              Object.assign(new Error(gettext('No response data')), {
                code: 560,
              })
            );
            return;
          }

          for (idx = 0, len = result.length; idx < len; idx++) {
            resultElm = result[idx];
            if (timeStamp !== null) {
              resultElm['x-time-request'] = timeStamp;
            }
            if (resp_timestamp !== null) {
              resultElm['x-time-response'] = resp_timestamp;
            }
            if (resultElm.status && resultElm.status.code) {
              cnt++;
              errs.push(resultElm);
            }
          }

          if (cnt == len) {
            respData.code = 562;
            respData.errors = errs;
            if (errs.length > 0) {
              respData.message =
                '[' +
                errs[0].status?.code +
                '] ' +
                (errs[0].status?.message || '');
            }
            reject(Object.assign(new Error(), respData));
          } else {
            resolve(result);
          }
        },
        function (err: JsonResponseError<Data>) {
          _rmUUID(uuid);
          if (err?.__CANCEL__) {
            sendCancel(uuid);
            rejectWithCancel(reject);
            return;
          }

          if (err?.response?.data) {
            const result =
              'result' in err.response.data
                ? err.response.data.result
                : err.response.data.data?.result;
            reject(Object.assign(new Error(), result?.[0]?.status));
          } else {
            reject(
              Object.assign(new Error(gettext('Unknown error')), { code: 400 })
            );
          }
        }
      );
  });
}

function _getBaseURL(
  type: FazUrlType = 'query',
  rpcCache: boolean = false
): string {
  // normal urls to box
  let url = _base_urls[type];
  if (rpcCache) {
    url += '?rpccache=1';
  }

  return url;
}

function getTimestampFromHttpHead(
  resp: AxiosResponse,
  field: string = 'x-time-request'
): number | null {
  let timeStamp: number | null = parseInt(resp.headers[field] as string);
  if (isNaN(timeStamp)) {
    timeStamp = null; // response's header does not have 'x-time-request' field
  }

  return timeStamp;
}
