// compatible with python syntax: %(foo)s
// So translator can use one syntax for all string place holder.
export function printfd(
  str: string = '',
  obj: Record<string, number | string>
) {
  return str.replace(/%\(\w+\)s/g, function (match: string) {
    return String(obj[match.slice(2, -2)]);
  });
}
