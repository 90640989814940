/**
 * Adding interceptors to Axios instance to
 * 1. control maximum number of requests that are allowed to run at the same time (25 by default)
 * 2. find if there is duplicated request in the queue. If found, will not run it but clone the response from that found request instead
 *
 * Referenced from axios-concurrency
 * https://github.com/bernawil/axios-concurrency
 */

import {
  isAxiosError,
  type AxiosError,
  type AxiosInstance,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import { cloneDeep } from 'lodash';

type CachedRequest = InternalAxiosRequestConfig & {
  __noCache?: boolean;
  __cacheKey?: string;
};

type RequestHandler = {
  request: CachedRequest;
  resolver: (req: CachedRequest) => void;
};

type InterceptorId = {
  request: number | null;
  response: number | null;
};

type ManagerInstance = {
  maxConcurrent: number;
  queue: RequestHandler[];
  running: RequestHandler[];
  requestCache: Map<string, RequestHandler[]>;
  detach: VoidFunction;
  clearCache: VoidFunction;
};

export const httpManager = (
  axios: AxiosInstance,
  maxConcurrent: number = 25
): ManagerInstance => {
  if (maxConcurrent < 1)
    throw new Error(
      'Concurrency Manager Error: minimum concurrent requests is 1'
    );

  const instance: ManagerInstance = {
    maxConcurrent,
    queue: [],
    running: [],
    requestCache: new Map(),
    detach: () => {
      if (interceptors.request !== null) {
        axios.interceptors.request.eject(interceptors.request);
      }
      if (interceptors.response !== null) {
        axios.interceptors.response.eject(interceptors.response);
      }
    },
    clearCache: () => {
      // place cached requests back to queue
      instance.requestCache.forEach((cache) => {
        cache.forEach((handler) => {
          handler.request.__noCache = true;
          push(handler);
        });
      });
      instance.requestCache.clear();
    },
  };

  const interceptors: InterceptorId = {
    request: null,
    response: null,
  };

  /**
   * extra handling for requests that are cached to wait for the response from this cancelled request
   * @returns {Object}
   * @returns {boolean} return.aborted If the request is aborted
   * @returns {boolean} return.foundNext If the request has another non-cancelled request in its cache
   */
  const handleAbortedRequest = (
    request: CachedRequest
  ): { aborted: boolean; foundNext: boolean } => {
    if (!request.signal?.aborted) return { aborted: false, foundNext: false };

    const result = { aborted: true, foundNext: false };

    const cacheKey = request.__cacheKey;
    let cache: RequestHandler[] | undefined;
    if (
      cacheKey &&
      isExistedCache((cache = instance.requestCache.get(cacheKey)))
    ) {
      // need to find the next non-cancelled request from its cache to resolve so that it won't get stuck
      let nextReq: RequestHandler | undefined;
      do {
        nextReq = cache.shift();
      } while (nextReq?.request.signal?.aborted);

      if (nextReq) {
        nextReq.resolver(nextReq.request);
        instance.running.push(nextReq);
        result.foundNext = true;
      } else {
        // if no other non-cancelled request found, can delete the cache
        instance.requestCache.delete(cacheKey);
      }
    }
    return result;
  };

  const shiftInitial = (): void => {
    setTimeout(() => {
      if (instance.running.length < instance.maxConcurrent) {
        shift();
      }
    }, 0);
  };

  const shift = (): void => {
    if (instance.queue.length) {
      const queued = instance.queue.shift() as RequestHandler;

      const { aborted, foundNext } = handleAbortedRequest(queued.request);
      if (!aborted) {
        queued.resolver(queued.request);
        instance.running.push(queued);
      } else if (!foundNext) {
        // can shift the next queue if no other non-cancelled request found from the cache of this cancelled request
        shift();
      }
    }
  };

  const push = (reqHandler: RequestHandler): void => {
    if (!(reqHandler.request.signal?.aborted || reqHandler.request.__noCache)) {
      const cacheKey = generateCacheKey(reqHandler.request);
      reqHandler.request.__cacheKey = cacheKey;

      if (cacheKey) {
        const cache = instance.requestCache.get(cacheKey);

        // if same request is in queue already, put it in the requestCache map,
        // otherwise put it in the normal queue
        if (isExistedCache(cache)) {
          cache.push(reqHandler);
          return;
        } else {
          instance.requestCache.set(cacheKey, []);
        }
      }
    }

    instance.queue.push(reqHandler);
    shiftInitial();
  };

  // Use as interceptor. Queue outgoing requests
  const requestHandler = (req: CachedRequest): Promise<CachedRequest> => {
    return new Promise((resolve) => {
      push({ request: req, resolver: resolve });
    });
  };

  // Use as interceptor. Execute queued request upon receiving a response
  const responseHandler = <
    T extends (AxiosResponse | AxiosError) & { __isCached?: boolean }
  >(
    res: T
  ): T => {
    if (res.config) {
      const { aborted, foundNext } = handleAbortedRequest(res.config);

      if (!aborted) {
        const cacheKey = (res.config as CachedRequest).__cacheKey;
        let cache: RequestHandler[] | undefined;

        if (
          cacheKey &&
          isExistedCache((cache = instance.requestCache.get(cacheKey)))
        ) {
          // found duplicated requests in the cache map
          // clone the response and resolve (if success) / reject (if error) it immediately
          cache.forEach((cache) => {
            if (MACROS.SYS.CONFIG_DEBUG) {
              // eslint-disable-next-line
              console.debug('duplicated request:', cacheKey);
            }
            cache.resolver({
              ...cache.request,
              adapter: () => {
                return new Promise((resolve, reject) => {
                  const clonedRes = cloneDeep(res);
                  clonedRes.config = cache.request;
                  clonedRes.__isCached = true;
                  if (isAxiosError(clonedRes)) {
                    reject(clonedRes);
                  } else {
                    resolve(clonedRes);
                  }
                });
              },
            });
          });
          instance.requestCache.delete(cacheKey);
        }
      } else if (foundNext) {
        // if the next non-cancelled request is found from the cache of this cancelled request,
        // don't have to shift the queue as that found request will be the next one to be resolved
        instance.running.shift();
        return res;
      }
    }

    instance.running.shift();
    shift();
    return res;
  };

  const responseErrorHandler = (res: AxiosError): Promise<never> => {
    return Promise.reject(responseHandler(res));
  };

  // queue concurrent requests
  interceptors.request = axios.interceptors.request.use(requestHandler);
  interceptors.response = axios.interceptors.response.use(
    responseHandler,
    responseErrorHandler
  );
  return instance;
};

// 1. cacheKey will not contain uuid
// 2. if data is a FormData obj, will not return a cacheKey, as it usually contains blob
export const generateCacheKey = ({
  data,
  method,
  url,
  params,
}: Partial<InternalAxiosRequestConfig>): string | undefined => {
  // not generate cacheKey if the type of the data is not in the whitelist
  if (!canGenCacheKey(data)) return;

  // configs to be cached, params will be included for 'get' method
  const cacheConfig = {
    data,
    method,
    url,
    ...(method === 'get' && { params }),
  };

  // do not remove id field in the cacheKey for /p request
  let gotReqId = url?.startsWith('/p/');
  const cacheKey = JSON.stringify(cacheConfig, (key, value) => {
    // remove uuid in the cacheKey
    if (!gotReqId && key === 'id') {
      gotReqId = true;
      return '';
    }
    return value;
  });
  return cacheKey;
};

// check if the type of the data that can be stringify to cacheKey
const canGenCacheKey = (data: unknown): boolean => {
  return (
    Object.prototype.toString.call(data) === '[object Object]' ||
    Array.isArray(data) ||
    typeof data === 'string' ||
    data === null ||
    data === undefined
  );
};

const isExistedCache = (
  requestHandlers: RequestHandler[] | undefined
): requestHandlers is RequestHandler[] => {
  return Array.isArray(requestHandlers);
};
