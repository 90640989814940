import { get, isArray, isNil } from 'lodash';

export function getDetailErrorMsg(error: any, defaultErrMsg?: string): any {
  const errors = [
    get(error, 'data.result.0.status.message'),
    get(error, 'message'),
    get(error, 'errors'),
    defaultErrMsg,
  ];
  return errors.find((error) => !isNil(error));
}

export function getMultipleDetailedErrorMsg(errors: any): string[] | undefined {
  const errMsgs: string[] = [];
  const results = get(errors, 'data.result');

  if (!results || (Array.isArray(results) && !results.length)) {
    return;
  }

  for (const result of results) {
    if (!result) continue;
    const { code, message } = result.status || {};
    if (message && code !== 0) {
      errMsgs.push(message as string);
    }
  }

  return errMsgs;
}

interface ErrorMessage {
  message: string;
}

/**
 * @param {unknown} error
 * @returns {(string | undefined)[] | ErrorMessage | undefined}
 */
export const toErrMsgParam = (
  error: any
): (string | undefined)[] | ErrorMessage | undefined => {
  if (!isArray(error?.data?.result)) return;
  const results = (
    error.data.result as { data?: ErrorMessage; status?: ErrorMessage }[]
  ).map((result) => {
    return result.data?.message ? result.data : result.status;
  });

  return results.length > 1
    ? results.map((status?: ErrorMessage) => status?.message)
    : results[0];
};

export function withErrMsgParam<T, A extends any[]>(
  query: (...params: A) => Promise<T>
): (...params: A) => Promise<T> {
  return (...args: A): Promise<T> => {
    return query(...args).catch((ex) => {
      throw toErrMsgParam(ex) as Error;
    });
  };
}
