import type { AxiosError, AxiosResponse } from 'axios';
import { fiHttp } from './http';
import { genv4 } from 'fi-web/fi-uuid';
import { printfd } from 'kit/kit-string';
import type {
  JsonRequest,
  JsonRequestParam,
  JsonResponse,
  JsonResponseDataV1,
  JsonResponseDataV2,
  JsonResponseError,
  JsonResponseResult,
  JsonResponseStatus,
} from './types';
import { toString } from 'lodash';

type FmgUrlType = 'query' | 'proxy' | 'forward' | 'get' | 'post';

type ProxyJsonRequestParam = JsonRequestParam<{
  target: string[];
  action: string;
  resource: string;
  prefix?: string;
  payload?: ProxyPayload | ProxyPayload[];
}>;

type ProxyTarget = string | string[] | null;

type ProxyPayload = string | Record<string, string | number | boolean>;

type ProxyResource = Partial<{
  target: ProxyTarget;
  path: string;
  name: string;
  payload: ProxyPayload | ProxyPayload[];
  action: string;
  'no-action': boolean | 1 | 0;
  'api type': 'service' | 'cmdb' | 'monitor';
  mkeys: string;
  extra: ProxyExtra;
}>;

type ProxyExtra = Partial<{
  timeout: number;
  [key: string]: any;
}>;

let _recording: boolean = false;
const _uuids: Set<string> = new Set();
const _canceled_uuids_with_pending_promise: Set<string> = new Set();

export const ERR_CODE_CLIENT_ABORT: number = 452;

const _base_urls: { [key in FmgUrlType]: string } = {
  query: '/cgi-bin/module/flatui/json',
  proxy: '/cgi-bin/module/flatui/json',
  forward: '/cgi-bin/module/forward',
  get: '/cgi-bin/module/flatui_proxy',
  post: '/cgi-bin/module/flatui_proxy',
};

export const fiFmgHttp = {
  startRecording,
  stopRecording,
  query,
  forward,
  get,
  post,
  download,
  blobDownload,
  proxy,
  genProxyRequest,
  ERR_CODE_CLIENT_ABORT,
};

const isObject = (val: unknown): boolean => {
  return Object.prototype.toString.call(val) === '[object Object]';
};
const isDefined = (val: unknown): boolean => typeof val !== 'undefined';

function startRecording(): void {
  _recording = true;
}

function stopRecording(): void {
  _recording = false;

  if (_uuids.size) {
    sendCancel(Array.from(_uuids));
  }

  for (const uuid of _uuids) {
    _canceled_uuids_with_pending_promise.add(uuid);
  }

  // clear uuids
  _uuids.clear();
}

function sendCancel(id: string[] | string): void {
  const ids = Array.isArray(id) ? id : [id];
  const req = {
    id: '1',
    method: 'exec',
    params: [
      {
        data: {
          id: ids,
        },
        url: 'sys/request/cancel',
      },
    ],
  };

  fiHttp
    .post(_getBaseURL('forward'), req, {
      params: {
        nocache: Date.now(),
      },
    })
    .catch((err: AxiosError) => err);
}

function rejectWithCancel(reject: (reason?: JsonResponseStatus) => void): void {
  return reject({
    code: ERR_CODE_CLIENT_ABORT,
    message: gettext('Request was aborted.'),
  });
}

function _addUUID({ id, method }: JsonRequest): void {
  // Mantis 616271, to avoid cancel "update" and "set" json requests
  // only block 'get' method.
  if (method === 'get') {
    _uuids.add(id as string);
  }
}

function _rmUUID(uuid: string): void {
  _uuids.delete(uuid);
}

function _commonQuery({
  req,
  type = 'query',
  abortCtrl,
  ...rest
}: {
  req: JsonRequest;
  type?: FmgUrlType;
  abortCtrl?: AbortController;
}): Promise<JsonResponseResult[]> {
  type Data = JsonResponseDataV2;

  return new Promise((resolve, reject) => {
    const uuid = genv4();

    if (req && isObject(req)) {
      req.id = uuid;
      if (_recording) {
        _addUUID(req);
      }
    }

    // request data
    const nReq = req;

    fiHttp
      .post<JsonResponse<Data>>(_getBaseURL(type), nReq, {
        params: {
          nocache: Date.now(),
        },
        signal: abortCtrl?.signal,
        ...rest,
      })
      .then(
        function (resp) {
          _rmUUID(uuid);
          _canceled_uuids_with_pending_promise.delete(uuid);
          resolve(resp?.data?.data?.result);
        },
        function (err: JsonResponseError<Data>) {
          _rmUUID(uuid);

          const isCanceled = _canceled_uuids_with_pending_promise.has(uuid);
          // If we already send the cancel request to the backend
          if (isCanceled) {
            _canceled_uuids_with_pending_promise.delete(uuid);
          }
          // when request was cancelled by abortControl
          // will receive err in reject. And we havn't sent the abort request yet.
          else if (err?.__CANCEL__) {
            sendCancel(uuid);
          }
          if (err?.__CANCEL__ || isCanceled) {
            rejectWithCancel(reject);
          } else {
            reject(Object.assign(new Error(), err?.response?.data || err));
          }
        }
      );
  });
}

/**
 * send request to /cgi-bin/module/flatui/json
 * GUI server side will parse json response and scan and report errors as need
 * it may be slow since GUI server will parse json string to json object
 * after that GUI server side send it back to client as stringify again.
 * but in certain case, we need it. for example, fill out vdoms which are not belong to current adom.
 * @param {JsonRequest} req
 * @param {AbortController=} abortCtrl
 * @returns {Promise<JsonResponseResult[]>}
 */
function query(
  req: JsonRequest,
  abortCtrl?: AbortController
): Promise<JsonResponseResult[]> {
  return _commonQuery({ req, abortCtrl });
}

/**
 * send request to /cgi-bin/module/forward
 * GUI server side will NOT parse json response and directly send back to client side
 * for performance boost, however, it will be out of control/scan/checking in GUI server side.
 * @param {JsonRequest} req
 * @param {boolean} [rpcCache=false] - true to enable RPC cache
 * @param {AbortController=} abortCtrl
 * @returns {Promise<JsonResponseResult[]>}
 */
function forward<TR = any>(
  req: JsonRequest,
  rpcCache: boolean = false,
  abortCtrl?: AbortController
): Promise<JsonResponseResult<TR>[]> {
  type Data = JsonResponseDataV1 | JsonResponseDataV2;
  const url = _getBaseURL('forward', rpcCache);

  return new Promise(function (resolve, reject) {
    const uuid = genv4();
    if (req && isObject(req)) {
      req.id = uuid;
      if (_recording) {
        _addUUID(req);
      }
    }

    const nReq = req;

    fiHttp
      .post<JsonResponse<Data>>(url, nReq, {
        params: {
          nocache: Date.now(),
        },
        signal: abortCtrl?.signal,
      })
      .then(
        function (resp) {
          _rmUUID(uuid);
          _canceled_uuids_with_pending_promise.delete(uuid);
          let idx: number,
            len: number,
            result: JsonResponseResult[],
            resultElm: JsonResponseResult,
            cnt: number = 0;
          const errs: JsonResponseResult[] = [];

          const timeStamp = getTimestampFromHttpHead(resp);
          const resp_timestamp = getTimestampFromHttpHead(
            resp,
            'x-time-response'
          );
          const respData = resp.data;

          if ('result' in respData) {
            // restrict admin profile response
            result = respData.result;
          } else if ('data' in respData) {
            // normal admin response
            result = respData.data.result || [];
          } else {
            reject(
              Object.assign(new Error(gettext('No response data')), {
                code: 560,
              })
            );
            return;
          }

          for (idx = 0, len = result.length; idx < len; idx++) {
            resultElm = result[idx];
            if (timeStamp !== null) {
              resultElm['x-time-request'] = timeStamp;
            }
            if (resp_timestamp !== null) {
              resultElm['x-time-response'] = resp_timestamp;
            }
            if (resultElm.status && resultElm.status.code) {
              cnt++;
              errs.push(resultElm);
            }
          }

          if (cnt == len) {
            respData.code = 562;
            respData.errors = errs;
            if (errs.length > 0) {
              respData.message =
                '[' +
                errs[0].status?.code +
                '] ' +
                (errs[0].status?.message || '');
            }
            reject(Object.assign(new Error(), respData));
          } else {
            resolve(result);
          }
        },
        function (err: JsonResponseError<Data>) {
          _rmUUID(uuid);

          const isCanceled = _canceled_uuids_with_pending_promise.has(uuid);
          // If we already send the cancel request to the backend
          if (isCanceled) {
            _canceled_uuids_with_pending_promise.delete(uuid);
          }
          // when request was cancelled by abortControl
          // will receive err in reject. And we havn't sent the abort request yet.
          else if (err?.__CANCEL__) {
            sendCancel(uuid);
          }
          if (err?.__CANCEL__ || isCanceled) {
            rejectWithCancel(reject);
            return;
          }

          if (err?.response?.data) {
            const result =
              'result' in err.response.data
                ? err.response.data.result
                : err.response.data.data?.result;
            reject(Object.assign(new Error(), result?.[0]?.status));
          } else {
            reject(
              Object.assign(new Error(gettext('Unknown error')), { code: 400 })
            );
          }
        }
      );
  });
}

/**
 * send request to /cgi-bin/module/flatui_proxy in POST
 * Usually, url in the req parameters are '/gui/xxxx', e.g: /gui/adom/dvm/device_sslvpn
 * the request handled by GUI server/ *.cpp, not from back-end json api.
 * @param {JsonRequest} req
 * @param {AbortController=} [abortCtrl]
 * @returns {Promise<JsonResponseResult[]>}
 */
function post<TR = any>(
  req: JsonRequest,
  abortCtrl?: AbortController
): Promise<JsonResponseResult<TR>[]> {
  type Data = JsonResponseDataV1;
  const url = _getBaseURL('post');
  const nReq = req;

  return new Promise(function (resolve, reject) {
    fiHttp
      .post<JsonResponse<Data>>(url, nReq, {
        params: {
          nocache: Date.now(),
        },
        signal: abortCtrl?.signal,
      })
      .then(
        function (resp) {
          try {
            const result = resp.data.result || [];
            const timeStamp = getTimestampFromHttpHead(resp);
            if (timeStamp) {
              for (let ii = 0; ii < result.length; ii++) {
                if (result[ii]) {
                  result[ii]['x-time-request'] = timeStamp;
                }
              }
            }
            resolve(result);
          } catch {
            reject(
              Object.assign(new Error(gettext('Get data error')), { code: 560 })
            );
          }
        },
        function (err: JsonResponseError<Data>) {
          if (err.__CANCEL__) {
            rejectWithCancel(reject);
          } else {
            reject(Object.assign(new Error(), err.response?.data || err));
          }
        }
      );
  });
}

/**
 * send request to /cgi-bin/module/flatui_proxy in GET
 * Usually, url in the req parameters are '/gui/xxxx', e.g:
 * the request handled by GUI server/ *.cpp, not from back-end json api.
 * @param {JsonRequest} req
 * @param {AbortController=} abortCtrl
 * @returns {Promise<JsonResponseResult[]>}
 */
function get(
  req: JsonRequest,
  abortCtrl?: AbortController
): Promise<JsonResponseResult[]> {
  type Data = JsonResponseDataV1;
  const url = _getBaseURL('get');
  const nReq = req;

  return new Promise(function (resolve, reject) {
    fiHttp
      .get<JsonResponse<Data>>(url, {
        params: nReq,
        signal: abortCtrl?.signal,
      })
      .then(
        function (resp) {
          try {
            resolve(resp.data.result || []);
          } catch {
            reject(
              Object.assign(new Error(gettext('Get data error')), { code: 560 })
            );
          }
        },
        function (err: JsonResponseError<Data>) {
          if (err.__CANCEL__) {
            rejectWithCancel(reject);
          } else {
            reject(Object.assign(new Error(), err.response?.data));
          }
        }
      );
  });
}

function _getBaseURL(
  type: FmgUrlType = 'forward',
  rpcCache: boolean = false
): string {
  // normal urls to box
  let url = _base_urls[type];
  if (rpcCache) {
    url += '?rpccache=1';
  }

  return url;
}

/**
 * download file from server
 * @param {string} url
 * @param {JsonRequest} req
 * @returns {Promise<{ filename: string, data: string }>}
 *
 */
function download(
  url: string,
  req: JsonRequest
): Promise<{ filename: string; data: string }> {
  // reset idle timer
  // fiIdleTimer.active();

  return new Promise(function (resolve, reject) {
    fiHttp.post<string>(url, req).then(
      function (resp) {
        // handle x-download type respondse
        if (resp.headers['content-type'] === 'application/x-download') {
          let fname: string = resp.headers['content-disposition'];
          const index = fname.search('filename') + 9; // exclude "filename=";
          fname = fname.substring(index);
          resolve({
            filename: fname,
            data: resp.data,
          });
        }

        reject(null as unknown as Error);
      },
      function (err: JsonResponseError<JsonResponseDataV1>) {
        reject(
          Object.assign(new Error(), err.response?.data?.result[0].status)
        );
      }
    );
  });
}

/**
 * download file from server
 * @param {string} url
 * @param {object} req
 * @returns {Promise<{ filename: string, data: Blob }>}
 *
 */
function blobDownload(
  url: string,
  req: JsonRequest
): Promise<{ filename: string; data: Blob }> {
  return fiHttp<Blob>({
    url: url,
    method: 'POST',
    data: req,
    responseType: 'blob',
  }).then(function (resp) {
    let fname: string;
    // Gets file name
    try {
      fname =
        (resp.headers['content-disposition'] as string).match(
          /^attachment;filename=(.+)/m
        )?.[1] ?? 'unamedfile';
    } catch {
      fname = 'unamedfile';
    }
    return {
      data: resp.data,
      filename: fname,
    };
  });
}

function proxy(
  target: ProxyTarget,
  method: string,
  resource: ProxyResource | ProxyResource[],
  ver: 'v1' | 'v2' = 'v2',
  extra?: ProxyExtra,
  abortCtrl?: AbortController
): Promise<JsonResponseResult[]> {
  method = method.toLowerCase();
  // fiIdleTimer.active();

  if (ver == 'v1') {
    return proxy_v1(target, method, resource, abortCtrl);
  }

  return proxy_v2(target, method, resource, extra, abortCtrl);
}

function proxy_v1(
  target: ProxyTarget,
  method: string,
  resource: ProxyResource | ProxyResource[],
  abortCtrl?: AbortController
): Promise<JsonResponseResult[]> {
  const params: ProxyJsonRequestParam[] = [];

  resource = Array.isArray(resource) ? resource : [resource];

  //var isMultiple = resource.length > 1;

  resource.forEach(function (res: ProxyResource) {
    let str: string = '';
    const apiType = res['api type'] || 'monitor';
    let oTarget = (res.target || target) as Exclude<ProxyTarget, null>;

    oTarget = Array.isArray(oTarget) ? oTarget : [oTarget];

    if (res.path && res.name) {
      str = '/api/' + apiType + '?';

      if (apiType == 'cmdb' && method == 'put' && !res.action) {
        res.action = 'edit';
      }

      ['path', 'name', 'action'].forEach(function (pm) {
        const param =
          res[pm as keyof Pick<ProxyResource, 'path' | 'name' | 'action'>];
        if (isDefined(param)) {
          str += '&' + pm + '=' + param;
        }
      });
    } else {
      str = printfd('/api/%(apiType)s?', { apiType });
    }

    let payload = res.payload || null; // it could not exist

    if (method == 'get') {
      if (payload) {
        if (Array.isArray(payload)) {
          payload = payload[0];
        }

        if (typeof payload === 'string') {
          str += payload;
        } else {
          for (const prop in payload) {
            str += '&' + prop + '=' + encodeURIComponent(payload[prop]);
          }
        }
      }

      params.push({
        url: 'sys/proxy/json',
        data: {
          target: oTarget,
          action: 'get',
          resource: str,
        },
      });
    } else {
      if (payload && !Array.isArray(payload)) {
        payload = [payload];
      }

      params.push({
        url: 'sys/proxy/json',
        data: {
          target: oTarget,
          action: 'post',
          resource: str,
          prefix: 'json=',
          payload: payload || undefined,
        },
      });
    }
  });

  return _commonQuery({
    req: {
      method: 'exec',
      params: params,
    },
    type: 'proxy',
    abortCtrl,
  }).then(function (resp) {
    return resp || [];
  });
}

function proxy_v2(
  target: ProxyTarget,
  method: string,
  resource: ProxyResource | ProxyResource[],
  extra?: ProxyExtra,
  abortCtrl?: AbortController
): Promise<JsonResponseResult[]> {
  return _commonQuery({
    req: genProxyRequest(target, method, resource, extra),
    type: 'proxy',
    abortCtrl,
  }).then(
    function (resp) {
      return resp || [];
    },
    () => []
  );
}

function genProxyRequest(
  target: ProxyTarget,
  method: string,
  resource: ProxyResource | ProxyResource[],
  extra?: ProxyExtra
): JsonRequest {
  const params: ProxyJsonRequestParam[] = [];
  resource = Array.isArray(resource) ? resource : [resource];

  resource.forEach(function (res: ProxyResource) {
    let str: string = '';
    let oTarget = (res.target || target) as Exclude<ProxyTarget, null>;

    oTarget = Array.isArray(oTarget) ? oTarget : [oTarget];

    if (res['api type'] === 'service') {
      str = printfd('/api/v2/service/%(path)s/%(name)s', {
        path: toString(res.path),
        name: toString(res.name),
      });
    } else if (res['api type'] === 'cmdb') {
      str = printfd('/api/v2/cmdb/%(path)s/%(name)s', {
        path: toString(res.path),
        name: toString(res.name),
      });
      if (res.mkeys) {
        const mkeys: string[] = Array.isArray(res.mkeys)
          ? res.mkeys
          : [res.mkeys];
        mkeys.forEach(function (key) {
          str += '/' + key;
        });
      }
      if (res.action) {
        str += '?action=' + res.action;
      }
    } else if (res['no-action']) {
      str = printfd('/api/v2/monitor/%(path)s/%(name)s', {
        path: toString(res.path),
        name: toString(res.name),
      });
    } else {
      str = printfd('/api/v2/monitor/%(path)s/%(name)s/%(action)s', {
        path: toString(res.path),
        name: toString(res.name),
        action: res.action || 'select',
      });
    }
    let payload = res.payload || null;

    if (method == 'get') {
      if (payload) {
        if (Array.isArray(payload)) {
          payload = payload[0];
        }

        str += '?';
        if (typeof payload === 'string') {
          str += payload;
        } else {
          for (const prop in payload) {
            str += '&' + prop + '=' + encodeURIComponent(payload[prop]);
          }
        }
      }

      params.push({
        url: 'sys/proxy/json',
        data: {
          target: oTarget,
          action: method,
          resource: str,
          ...res.extra,
          ...extra,
        },
      });
    } else {
      if (payload) {
        if (!Array.isArray(payload)) {
          // avoid empty object
          if (!Object.keys(payload).length) {
            payload = null;
          }
        } else {
          if (payload && method == 'put') {
            payload = payload[0];
          }
        }
      }
      params.push({
        url: 'sys/proxy/json',
        data: {
          target: oTarget,
          action: method,
          resource: str,
          payload: payload || undefined,
          ...res.extra,
          ...extra,
        },
      });
    }
  });

  return {
    method: 'exec',
    params: params,
  };
}

function getTimestampFromHttpHead(
  resp: AxiosResponse,
  field: string = 'x-time-request'
): number | null {
  let timeStamp: number | null = parseInt(resp.headers[field] as string);
  if (isNaN(timeStamp)) {
    timeStamp = null; // response's header does not have 'x-time-request' field
  }

  return timeStamp;
}
